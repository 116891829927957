import { FC } from 'react'
import { Row, Col, Text, Image, List, ListItem } from '@/client/components'
import { useRouterPush } from '@/client/hooks'
import * as S from './styles'
import { AboutProps } from '../types'

const About: FC<AboutProps> = ({ about: { title, description, image, button, list } }) => {
  const { routerPush } = useRouterPush()

  return (
    <S.About>
      <Row>
        <Col xs={12} xl={5}>
          <Text size='xxlg' weight='medium' lineHeight='1.2'>
            {title}
          </Text>
          {description.map(({ paragraph }) => (
            <Text key={paragraph} color='weak' lineHeight='1.4'>
              {paragraph}
            </Text>
          ))}
          <S.Button onClick={() => routerPush(button.link)} kind='weak'>
            {button.label}
          </S.Button>
        </Col>

        <Col xs={12} lg={6} xl={4}>
          <S.BorderImage>
            <Image src={image.url} alt={image.alt} width={'300'} height={'510'} />
          </S.BorderImage>
        </Col>

        <Col xs={12} lg={6} xl={3}>
          <List kind='gold'>
            {list.map(({ item }) => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </List>
        </Col>
      </Row>
    </S.About>
  )
}

export default About
