import { FC } from 'react'
import { Row, Col, Text, List, ListItem } from '@/client/components'
import * as S from './styles'
import { AdvantagesProps, RatingProps } from '../types'

const settings = {
  dots: true,
  arrows: false,
  infinite: true,
  fade: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
}

const Customer: FC<RatingProps> = ({ rating, name, position, company, image, testimonial }) => (
  <S.Customer>
    <S.Rating stars={5} filled={Number(rating)} />
    <Text color='weak' size='sm'>
      {testimonial}
    </Text>

    <S.CustomerInfos>
      <S.CustomerInfosLeft>
        <S.AvatarFirst image={image.url} alt={image.alt} hasBorder />
      </S.CustomerInfosLeft>
      <div>
        <Text size='sm' weight='bold' margin='0'>
          {name}
        </Text>
        <Text size='xs' color='weak' margin='0'>
          {position} - {company}
        </Text>
      </div>
    </S.CustomerInfos>
  </S.Customer>
)

const Advantages: FC<AdvantagesProps> = ({ advantages: { title, description, ratings } }) => (
  <S.Advantages>
    <Row>
      <S.Container>
        <Text size='xlg' family='heading' weight='bold' margin='0 0 2rem'>
          {title}
        </Text>
        <Row>
          <Col xs={12} lg={5}>
            {/*  @ts-ignore */}
            <etrusted-widget data-etrusted-widget-id='wdg-487ea5b9-83b7-4245-b755-fc9d78fa84bf'></etrusted-widget>
            <Text lineHeight='1.4'>{description}</Text>
          </Col>

          <Col xs={12} lg={7}>
            {/*  @ts-ignore */}
            <etrusted-widget data-etrusted-widget-id='wdg-694ae81b-501b-40c6-ae55-c53553c046ba'></etrusted-widget>
          </Col>
        </Row>
      </S.Container>
    </Row>
  </S.Advantages>
)

export default Advantages
