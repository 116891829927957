import styled, { keyframes } from 'styled-components'
import { theme } from '@/client/components'
import Slider from 'react-slick'
const { space, color, breakpoint } = theme

export const Hero = styled.div`
  position: relative;
  width: 100%;
  height: 25.5rem;
  overflow: hidden;
  background: linear-gradient(to bottom, #d6f1f4 0%, #ffffff 100%);
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoint.lg2}) {
    height: 26rem;
  }

  @media (min-width: ${breakpoint.xl2}) {
    height: 33rem;
  }

  @media (min-width: ${breakpoint.hd}) {
    height: 32rem;
  }
`

export const Slide = styled(Slider)<{ slider: boolean }>`
  width: 100%;

  @media (min-width: ${breakpoint.ul}) {
    max-width: ${(p) => (p.slider ? '142rem' : '100%')};
  }

  .slick-prev {
    display: block !important;
    left: ${space.xs2};
    height: 6rem;
    z-index: 9;

    @media (min-width: ${breakpoint.hd}) {
      top: 40%;
      left: 2rem;
    }

    @media (min-width: ${breakpoint.hd2}) {
      top: 42%;
      left: 5rem;
    }

    @media (min-width: ${breakpoint.ul}) {
      left: 20rem;
    }
  }

  .slick-next {
    display: block !important;
    right: ${space.xs2};
    height: 6rem;
    z-index: 9;

    @media (min-width: ${breakpoint.hd}) {
      top: 40%;
    }

    @media (min-width: ${breakpoint.hd2}) {
      top: 42%;
      right: 5rem;
    }

    @media (min-width: ${breakpoint.ul}) {
      right: 22rem;
    }
  }

  .slick-dots {
    text-align: center;
    bottom: 2.5rem;

    @media (min-width: ${breakpoint.lg2}) {
      text-align: justify;
      left: 5rem;
    }

    @media (min-width: ${breakpoint.hd}) {
      text-align: justify;
      left: 10rem;
      bottom: 6rem;
    }

    @media (min-width: ${breakpoint.hd2}) {
      left: 20.4rem;
      bottom: 4rem;
    }

    @media (min-width: ${breakpoint.ul}) {
      left: 30.4rem;
    }
  }

  .slick-dots li button:before {
    font-size: 14px;

    @media (min-width: ${breakpoint.hd}) {
      font-size: 18px;
    }
  }

  .slick-dots li {
    margin: 0;
  }

  .slick-dots li.slick-active button:before {
    color: grey;
  }

  .slick-dots button:before {
    opacity: 0.75;
    color: black;
  }
`

export const SliderContentWrapper = styled.div`
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  margin-top: ${space.md};
  margin-left: 1.2rem;
  padding-right: 2rem;

  @media (min-width: ${breakpoint.lg2}) {
    margin-left: 5rem;
  }

  @media (min-width: ${breakpoint.xl}) {
    margin-left: 5rem;
  }

  @media (min-width: ${breakpoint.xl2}) {
    padding-top: 3rem;
    padding-right: 0;
  }

  @media (min-width: ${breakpoint.hd}) {
    margin-top: ${space.md};
    margin-left: 10rem;
    padding-right: 3rem;
    padding-top: 0;
    justify-content: space-around;
    align-items: center;
    height: 25rem;
  }

  @media (min-width: ${breakpoint.hd2}) {
    margin-left: 20rem;
    padding-right: 31rem;
  }

  @media (min-width: ${breakpoint.ul}) {
    margin-left: 30rem;
    padding-right: 50rem;
  }
`

export const FirstBlock = styled.div`
  width: 80%;
  padding-left: ${space.xs2};

  & .subtitle {
    width: fit-content;
    background-color: ${color.primary.darker};
    padding: 0 ${space.xs2};
  }

  & .mainButtonIcon {
    font-size: ${space.md};
  }

  @media (min-width: ${breakpoint.lg2}) {
    width: 60%;
  }

  @media (min-width: ${breakpoint.xl2}) {
    padding-left: 0;
    width: 27%;
  }

  @media (min-width: ${breakpoint.hd}) {
    width: 70%;
    padding-right: 2rem;
  }
`

export const ImageBlock = styled.div`
  display: none;

  @media (min-width: ${breakpoint.xl2}) {
    display: block;
    width: 65%;
    max-width: 450px;

    & .img {
      position: relative;
      top: -3rem;
      width: 95%;
      height: auto;
    }
  }

  @media (min-width: ${breakpoint.hd}) {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    & .img {
      margin-right: 0;
      position: relative;
      top: 1.5rem;
      height: auto;
    }
  }
`

export const ThirdBlock = styled.div`
  display: none;

  @media (min-width: ${breakpoint.lg2}) {
    display: block;
    width: 55%;
    margin-top: ${space.xl4};
    margin-right: ${space.xl7};
  }

  @media (min-width: ${breakpoint.xl2}) {
    margin-right: ${space.xl};
    width: 50%;
  }

  @media (min-width: ${breakpoint.hd}) {
    display: flex;
    flex-direction: column;
    width: 115%;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
    align-items: left;
  }
`

export const ServiceDetails = styled.div<{ endButton?: boolean }>`
  display: flex;
  background-color: ${({ endButton }) => (endButton ? color.warning.pure : '#ffff')};
  border-radius: ${space.xl};
  align-items: center;
  border-radius: ${space.md};
  margin: ${space.sm};
  padding: 0 ${space.md};

  & .slide-link {
    display: flex;
    width: 100%;
    align-items: center;
  }

  @media (min-width: ${breakpoint.xl}) {
    width: 70%;
    border-radius: ${space.xl};
  }
  @media (min-width: ${breakpoint.hd}) {
    margin: ${space.xs};
  }
`

export const ImageAligner = styled.div`
  display: none;

  @media (min-width: ${breakpoint.xl}) {
    display: block;
    width: 40%;
  }
`

export const TextAligner = styled.div`
  width: 100%;
`

export const IconAligner = styled.div``

export const Button = styled.button`
  margin-left: ${space.md};
  border-radius: ${space.xl};
`

export const MainImageAligner = styled.div`
  display: flex !important;
  justify-content: center;
  overflow: hidden;
`
const fadeOut = keyframes`
  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`
export const HeroWrapper = styled.div`
  background: ${color.grayscale.headerGray};

  & #trigger-bar {
    position: relative;
    width: 5px;
    height: 30rem;
    background: ${theme.color.grayscale.white};
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;

    @media (max-width: ${breakpoint.xl2}) {
      left: 0 !important;
      display: none !important;
    }

    & > div.svg-wrapper {
      transform: scale(1);
      animation: scalesvgwrapper 1s ease inifinite;
    }
  }
`

export const opcKeyframes = keyframes`
  50%{
    opacity: 0.5;
    transform: scale(1.1)
  }
  100% {
    opacity: 1;
    transform: scale(1)
  }
`

export const BgWrap = styled.div`
  @media (max-width: ${breakpoint.xl2}) {
    background: none !important;
  }

  display: flex;
  justify-content: flex-start;
  height: 30rem;
  width: 100%;
  overflow: hidden;
  background-size: 1800px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;

  &.video-ready + .bg-lazy-cover {
    animation: ${fadeOut} 1s ease 2s forwards;
  }

  & .foreground-img {
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    overflow: hidden;

    @media (max-width: ${breakpoint.xl2}) {
      left: 0 !important;
    }
    & .foreground-img-core {
      width: 100%;
      height: 100%;
      position: relative;
      left: 0;
      top: 0;
      background-repeat: no-repeat !important;
      background-size: 1800px !important;
      background-position: center !important;

      @media (max-width: ${breakpoint.xl2}) {
        left: 0 !important;
      }
    }
  }

  & .trigger-svg-wrapper {
    animation: ${opcKeyframes};
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  & .trigger-svg-wrapper.clicked {
    animation: none !important;
  }
`

export const BgLazyCover = styled.div<{ $imageUrl: string; $bannerVidStatus: boolean }>`
  position: relative;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  background-image: ${(props) => `url(${props.$imageUrl})`};
  background-size: cover;
`
