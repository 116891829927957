import styled from 'styled-components'
import { theme } from '@/client/components'
const { color, space, borders, breakpoint } = theme

export const Services = styled.div`
  background-color: ${color.grayscale.white};
  padding-bottom: 10rem;
  padding-top: 0;
  @media (min-width: ${breakpoint.lg}) {
    padding-bottom: 5rem;
    padding-top: 2.5em;
  }
`

export const ArtworkService = styled.div`
  display: flex;
  background-color: ${color.grayscale.lightApricot};
  padding: ${space.lg};
  border-radius: ${space.md};
  margin-bottom: ${space.md};
  cursor: pointer;
  border-color: transparent;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 0px 7px 5px #1021331a;
  }
  img {
    display: none;
  }

  @media (min-width: ${breakpoint.lg2}) {
    img {
      margin-left: ${space.lg};
      display: block;
      position: relative;
      top: 1.5rem;
    }
  }

  @media (min-width: ${breakpoint.xl3}) {
    height: 15.2rem;
  }
  @media (min-width: ${breakpoint.xl4}) {
    height: 15rem;
  }
`

export const ProofingService = styled.div`
  cursor: pointer;
  background-color: ${color.grayscale.pastelGreen};
  padding: ${space.lg} ${space.md} ${space.md} ${space.md};
  border-radius: ${space.md};
  margin-bottom: ${space.md};
  border-color: transparent;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 0px 7px 5px #1021331a;
  }

  @media (min-width: ${breakpoint.xl3}) {
    height: 15.2rem;
  }
  @media (min-width: ${breakpoint.xl4}) {
    height: 15rem;
  }
`

export const DesignService = styled.div`
  cursor: pointer;
  display: flex;
  background-color: ${color.grayscale.babyBlue};
  padding: ${space.lg};
  border-radius: ${space.md};
  margin-top: ${space.md};
  margin-bottom: ${space.md};
  border-color: transparent;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 0px 7px 5px #1021331a;
  }
  img {
    display: none;
  }

  @media (min-width: ${breakpoint.lg2}) {
    img {
      margin-left: ${space.xs2};
      display: block;
      position: relative;
      top: 1rem;
    }
  }
  @media (min-width: ${breakpoint.xl3}) {
    height: 15.2rem;
  }
  @media (min-width: ${breakpoint.xl4}) {
    height: 14.7rem;
  }
  @media (min-width: ${breakpoint.hd}) {
    margin-bottom: 0;
  }
`

export const ExpressService = styled.div`
  cursor: pointer;
  background-color: ${color.grayscale.dustyPeach};
  padding: ${space.lg} ${space.md} ${space.md} ${space.md};
  border-radius: ${space.md};
  border-color: transparent;
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 0px 7px 5px #1021331a;
  }

  @media (min-width: ${breakpoint.xl3}) {
    height: 15.2rem;
  }
  @media (min-width: ${breakpoint.xl4}) {
    height: 14.7rem;
  }
`
