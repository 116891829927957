import styled from 'styled-components'
import { theme, Button as CButton } from '@/client/components'
const { color, borders, space, breakpoint } = theme

export const About = styled.div`
  padding: 0 0 ${space.xl4};
  background-color: ${color.grayscale.white};

  @media (min-width: ${breakpoint.lg}) {
    padding: ${space.xl4} 0;
  }
`

export const BorderImage = styled.div`
  max-width: 22rem;
  margin: 0 auto ${space.xl};
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoint.lg}) {
    margin: 0 auto ${space.xl};
  }

  img {
    display: block;
  }
`

export const Button = styled(CButton)`
  margin: ${space.xl} auto ${space.xl};

  @media (min-width: ${breakpoint.lg}) {
    margin: ${space.xl} 0;
  }
`
