import styled from 'styled-components'
import { theme } from '@/client/components'
const { color, space, borders, breakpoint } = theme

export const Labels = styled.div`
  background-color: ${color.grayscale.white};
  padding-bottom: 10rem;
  padding-top: 0;
  @media (min-width: ${breakpoint.lg}) {
    padding-bottom: 2.5rem;
    padding-top: 5rem;
  }
`

export const ArrowButtons = styled.div`
  position: relative;
  display: none;
  align-items: center;
  justify-content: flex-start;
  gap: ${space.md};

  button {
    position: static;
  }

  @media (min-width: ${breakpoint.lg}) {
    display: flex;
  }
`

export const LabelButton = styled.div`
  padding: 0 ${space.xs};
`

export const Label = styled.div`
  text-align: center;
  cursor: pointer;
  border-color: transparent;
  border-radius: 14px;
  border: hidden;
  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 0px 7px 5px #1021331a;
  }

  @media (min-width: ${breakpoint.lg}) {
    min-width: auto;
  }
`

export const LabelIcon = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${space.xl6};
  height: ${space.xl6};
  margin: 0 auto ${space.lg};
  border-radius: ${borders.radius.circle};
  background-color: ${({ bgColor }) => color.primary[bgColor]};

  svg {
    width: ${space.xl3};
    height: ${space.xl3};
  }
`
