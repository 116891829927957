import { FC } from 'react'
import Image from 'next/image'
import { Row, Col, Text } from '@/client/components'
import { useRouterPush, useWindowSize } from '@/client/hooks'
import { ServicesProps } from '../types'
import * as S from './styles'

const Services: FC<ServicesProps> = ({ services: { title, subtitle, list } }) => {
  const { routerPush } = useRouterPush()
  const windowSize = useWindowSize()
  const isLgBreakpoint = windowSize?.width > 768
  return (
    <S.Services>
      <Row>
        <Col xs={12} xl={4}>
          <Text size='xxlg' family='heading' weight='bold' align={isLgBreakpoint ? 'left' : 'center'} lineHeight='1.2'>
            {title}
          </Text>
          <Text margin='2rem 0 3rem' align={isLgBreakpoint ? 'left' : 'center'}>
            {subtitle}
          </Text>
        </Col>
        <Col xs={12} xl={8}>
          <Row>
            <Col xs={12} xl2={8}>
              <S.ArtworkService as='button' onClick={() => routerPush(list.artwork_service_link)}>
                <div>
                  <Text size='xxlg' weight='bold' align={isLgBreakpoint ? 'left' : 'center'} lineHeight='1.2'>
                    {list.artwork_service}
                  </Text>
                  <Text margin='0' lineHeight='1.4' align='left'>
                    {list.artwork_service_description}
                  </Text>
                </div>
                <Image
                  src={list.artwork_service_image.url}
                  width='160'
                  height='190'
                  alt={list.artwork_service_image.alt}
                />
              </S.ArtworkService>

              <S.DesignService as='button' onClick={() => routerPush(list.design_service_link)}>
                <div>
                  <Text
                    size='xxlg'
                    family='heading'
                    weight='bold'
                    align={isLgBreakpoint ? 'left' : 'center'}
                    lineHeight='1.2'
                  >
                    {list.design_service}
                  </Text>
                  <Text margin='0' lineHeight='1.4' align='left'>
                    {list.design_service_description}
                  </Text>
                </div>
                <Image
                  src={list.design_service_image.url}
                  width='110'
                  height='184'
                  alt={list.design_service_image.alt}
                />
              </S.DesignService>
            </Col>

            <Col xs={12} xl2={4}>
              <S.ProofingService as='button' onClick={() => routerPush(list.proofing_service_link)}>
                <Text
                  size='xxlg'
                  family='heading'
                  weight='bold'
                  align={isLgBreakpoint ? 'left' : 'center'}
                  lineHeight='1.2'
                >
                  {list.proofing_service}
                </Text>
                <Text margin='0' lineHeight='1.4' align='left'>
                  {list.proofing_service_description}
                </Text>
              </S.ProofingService>

              <S.ExpressService as='button' onClick={() => routerPush(list.express_production_link)}>
                <Text
                  size='xxlg'
                  family='heading'
                  weight='bold'
                  align={isLgBreakpoint ? 'left' : 'center'}
                  lineHeight='1.2'
                >
                  {list.express_production}
                </Text>
                <Text margin='0' lineHeight='1.4' align='left'>
                  {list.express_production_description}
                </Text>
              </S.ExpressService>
            </Col>
          </Row>
        </Col>
      </Row>
    </S.Services>
  )
}

export default Services
