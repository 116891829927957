import { FC, useCallback, useRef, useState, MutableRefObject, useMemo } from 'react'
import Image from 'next/image'
import { default as SliderProps } from 'react-slick'
import Slider from 'react-slick'
import { Row, Col, Text, HorizontalScroll } from '@/client/components'
import { useRouterPush, useWindowSize } from '@/client/hooks'
import { SliderArrow } from '@/client/submodules/Slider'
import * as S from './styles'
import { LabelsProps } from '../types'

const Labels: FC<LabelsProps> = ({ labels: { title, subtitle, list } }) => {
  const { routerPush } = useRouterPush()
  const sliderRef = useRef() as MutableRefObject<SliderProps>
  const [index, setIndex] = useState<number>(0)
  const windowSize = useWindowSize()
  const isLgBreakpoint = windowSize?.width > 891
  const showThreeSlides = windowSize?.width > 1239

  const handlePrev = useCallback(() => {
    sliderRef.current.slickPrev()
  }, [sliderRef])

  const handleNext = useCallback(() => {
    sliderRef.current.slickNext()
  }, [sliderRef])

  const beforeChange = useCallback((prev, next) => {
    setIndex(next)
  }, [])

  const settings = {
    infinite: true,
    speed: 10,
    beforeChange,
    slidesToShow: showThreeSlides ? 3 : 2,
    slidesToScroll: 1
  }

  const listLabels = useMemo(
    () =>
      list.map(({ images, link }) => (
        <S.LabelButton>
          <S.Label as='button' onClick={() => routerPush(link)}>
            <Image src={images.url} width='239' height='254' alt={images.alt} />
          </S.Label>
        </S.LabelButton>
      )),
    [list, routerPush]
  )

  return (
    <S.Labels>
      <Row>
        <Col xs={12} lg={4}>
          <Text size='xxlg' family='heading' weight='bold' align={isLgBreakpoint ? 'left' : 'center'} lineHeight='1.2'>
            {title}
          </Text>
          <Text color='weak' margin='2rem 0 3rem' align={isLgBreakpoint ? 'left' : 'center'}>
            {subtitle}
          </Text>
          {isLgBreakpoint && (
            <S.ArrowButtons>
              <SliderArrow
                position='left'
                onClick={handlePrev}
                currentSlide={index}
                slideCount={5}
                slidesToShow={3}
                isInfinite
              />
              <SliderArrow
                position='right'
                onClick={handleNext}
                currentSlide={index}
                slideCount={5}
                slidesToShow={3}
                isInfinite
              />
            </S.ArrowButtons>
          )}
        </Col>
        <Col xs={12} lg={8}>
          {!isLgBreakpoint && <HorizontalScroll>{listLabels}</HorizontalScroll>}

          {isLgBreakpoint && (
            <Row>
              <Col>
                <Slider ref={sliderRef} {...settings}>
                  {listLabels}
                </Slider>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </S.Labels>
  )
}

export default Labels
